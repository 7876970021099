import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../utils/api';

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { email } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();


  const handleResetPassword = async (e) => {
    e.preventDefault();

    // PASSWORD VALIDATION
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    }
    if (password.toLowerCase() === password) {
      toast.error("Password must contain at least one uppercase letter");
      return;
    }
    if (password.toUpperCase() === password) {
      toast.error("Password must contain at least one lowercase letter");
      return;
    }
    if (!/\d/.test(password)) {
      toast.error("Password must contain at least one number");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      //(token, password);
      const response = await api.post(`/user/reset-password`, {
        "token": token,
        "password": password
      });
      const data = response.data;
      if (response.status === 200) {
        const urlWithoutToken = window.location.pathname;
        window.history.replaceState(null, '', urlWithoutToken);
        toast.success('Password has been reset successfully.');
        setTimeout(() => {
          navigate('/signin');
        }, 3000);
      } else {
        toast.info(data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || "An error occurred. Please try again later.");
    }
  };

  return (
    <div className="bg-white text-black min-h-screen flex items-center justify-center">
      <ToastContainer />
      <div className="w-full max-w-md bg-gray-100 p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6 text-center">Set new password</h2>
        <form className="space-y-6" onSubmit={handleResetPassword}>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
            <input
              type="password"
              id="password"
              placeholder='Password'
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder='Confirm Password'
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-green-400 text-black px-4 py-2 rounded-lg hover:bg-green-500 font-semibold"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
