import EarlyBirdModal from "../HomePage/EarlyBirdModal";
import { useState } from "react";

export default function EarlyBird() {
  const [isModal, setIsModal] = useState(false);
  const closeModal = () => {
    setIsModal(false);
  };
  const handleClicked = () => {
    setIsModal(true);
  };
  return (
    <div className="absolute -top-1 right-4 z-50 flex flex-col items-center">
      <div className="w-[2px] h-3 sm:h-4 bg-gray-400"></div>
      <div className="bg-gradient-to-br from-white to-gray-100 text-black shadow-lg rounded-lg p-1 sm:p-2 px-3 sm:px-4 text-center animate-sway origin-top border-2 sm:border-4 border-dashed border-gray-300">
        <p className="text-[10px] sm:text-sm font-semibold">
          Early Bird Discount!
        </p>
        <span className="block text-base sm:text-lg font-bold text-green-500">
          $2 off
        </span>
        <button
          className="mt-1 sm:mt-2 bg-green-500 text-white rounded-md px-3 sm:px-4 py-1 hover:bg-green-600 text-xs sm:text-sm"
          onClick={handleClicked}
        >
          Learn More
        </button>
      </div>
      {isModal && <EarlyBirdModal closeModal={closeModal} />}
    </div>
  );
}
