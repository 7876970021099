import React, { useState, useEffect } from "react";
import ProfileTab from "./ProfileTab";
import PaymentsTab from "./PaymentsTab";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/AuthProvider";
import { ToastContainer } from "react-toastify";
import api from "../../utils/api";

export default function UserPage() {
  const { logout, token } = useAuth();
  const [userLevel, setUserLevel] = useState("");
  const [activeSection, setActiveSection] = useState("Profile");
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));

  useEffect(() => {
    const fetchLevel = async () => {
      if (token) {
        try {
          const response = await api.get(`/user/level`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setUserLevel(response.data.user_level);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchLevel();
  }, [token]);
  useEffect(() => {
    const fetchLevel = async () => {
      if (token) {
        try {
          const response = await api.get(`/user/level`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setUserLevel(response.data.user_level);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchLevel();
  }, [token]);
  const getFirst = (name) => {
    return name.charAt(0).toUpperCase();
  };

  return (
    <div className="min-h-screen bg-slate-50 flex flex-col md:flex-row">
      <aside className="bg-white shadow-md w-full md:w-1/4 p-6 flex flex-col justify-between">
        <div>
          <div className="flex flex-col items-center">
            <Link
              to="/app"
              alt="Back"
              className="self-start text-lg font-semibold text-gray-600 hover:text-gray-900 mb-6"
            >
              ← Back
            </Link>
            <div className="bg-green-500 text-white rounded-full w-20 h-20 mb-2 flex items-center justify-center text-2xl font-bold">
              {getFirst(user.first_name)}
            </div>
            <h2 className="text-xl font-semibold mb-4">
              {user.first_name + " " + user.last_name}
            </h2>
          </div>
          <nav className="mt-4">
            {["Profile"].map((section) => (
              <div
                key={section}
                className={`p-3 rounded-lg text-lg font-medium cursor-pointer mb-2 ${
                  activeSection === section
                    ? "bg-green-200 text-green-900"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
                onClick={() => setActiveSection(section)}
              >
                {section}
              </div>
            ))}
          </nav>
        </div>
        <div className="pt-6 border-t border-gray-200">
          <button
            onClick={logout}
            className="w-full p-3 bg-red-500 text-white rounded-lg hover:bg-red-600"
          >
            Logout
          </button>
        </div>
      </aside>

      <main className="flex-grow bg-white p-8 rounded-lg shadow-md mt-6 md:mt-0 md:ml-6">
        {activeSection === "Profile" && (
          <ProfileTab
            setActiveSection={setActiveSection}
            logout={logout}
            token={token}
            user={user}
            userLevel={userLevel}
          />
        )}
        {/* {activeSection === "Payments" && <PaymentsTab />} */}
      </main>
      <ToastContainer />
    </div>
  );
}
