import React, { useState, useEffect } from 'react';
import Navbar from '../../components/NavBar/NavBar';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../utils/api';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [cooldown, setCooldown] = useState(() => {
    const storedCooldown = sessionStorage.getItem('cooldown');
    return storedCooldown ? Number(storedCooldown) : 0;
  });
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prevCooldown) => {
          const newCooldown = prevCooldown - 1;
          
          sessionStorage.setItem('cooldown', newCooldown);
          return newCooldown;
        });
      }, 1000);
    } else {
      
      sessionStorage.removeItem('cooldown');
    }
    return () => clearInterval(timer);
  }, [cooldown]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      setCooldown(150); 
      sessionStorage.setItem('cooldown', 150); 
      const response = await api.post(
        `/email/forget-password?email=${email}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      toast.success('Password reset link sent to email.');
    } catch (error) {
      if(error.response?.data?.detail){
        setCooldown(0); 
        sessionStorage.setItem('cooldown', 0)
      }
      //console.log('Axios error:', error);
      toast.error(error.response?.data?.detail);
    }
  };

  return (
    <div className="bg-white text-black min-h-screen">
      <Navbar />
      <ToastContainer />
      <main className="container mx-auto py-12">
        <section className="flex justify-center items-center">
          <div className="w-full max-w-md bg-gray-100 p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-6 text-center">Forgot Password</h2>
            <form className="space-y-6" onSubmit={handleForgotPassword}>
              <div>
                <div className='flex mb-2'>
                  <p className="text-gray-600 text-sm mr-2">Find your account </p>
                  <p className={`text-red-500 text-sm ${cooldown <= 0 ? "hidden" : ""}`}>
                    {cooldown}s
                  </p>
                </div>
                <input
                  type="email"
                  id="email"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              {message && <p className="text-center text-red-600 mb-2">{message}</p>}

              <div>
                <button
                  type="submit"
                  disabled={cooldown > 1}
                  className={`w-full ${cooldown >= 1 ? "bg-gray-200 font-light" : "bg-green-400 hover:bg-green-500"} text-black px-4 py-2 rounded-lg font-semibold`}
                >
                  Send
                </button>
              </div>
            </form>
            <div className="mt-4 text-center">
              <p className="text-gray-600 text-sm">
                Enter the email address associated with your account, and we'll send you a link to reset your password.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
