import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot } from "@fortawesome/free-solid-svg-icons";
import remarkGfm from "remark-gfm";
import { FaRedo } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import LoadingText from "./LoadingText";

const CustomLink = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-blue-500 underline hover:text-blue-600"
  >
    {children}
  </a>
);
const MessageList = ({
  messages,
  isLoading,
  messagesEndRef,
  hasError,
  handleRegenerate,
  stopStream,
}) => {
  return (
    <>
      {messages.map((message, index) => (
        <div
          key={index}
          className={`mb-2 p-1 rounded flex ${
            message.sender === "assistant" || message.role === "assistant"
              ? "justify-start"
              : "justify-end"
          } w-full`}
        >
          {(message.sender === "assistant" || message.role === "assistant") && (
            <FontAwesomeIcon icon={faRobot} className="mr-1 text-blue-500" />
          )}
          <div
            className={`p-1 sm:max-w-[80%] rounded ${
              message.sender === "assistant" || message.role === "assistant"
                ? "bg-white"
                : "bg-green-300"
            }`}
            style={{ overflowWrap: "break-word", wordBreak: "break-word" }}
          >
            {message.role === "assistant" &&
            index === messages.length - 1 &&
            isLoading ? (
              <LoadingText text="Beth is fetching..." isLoading={isLoading} />
            ) : (
              <div className="markdown-content">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: ({ node, ...props }) => <CustomLink {...props} />,
                  }}
                >
                  {message.text_value || ""}
                </ReactMarkdown>
                {/* <button
                  onClick={stopStream}
                  hidden={!isLoading}
                  disabled={!isLoading}
                >
                  Stop Response
                </button> */}
                {hasError && index === messages.length - 1 && (
                  <button
                    onClick={handleRegenerate}
                    className="mt-2 px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-white text-sm font-semibold rounded-lg shadow-md flex items-center space-x-2 hover:from-red-600 hover:to-red-700 transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    <FaRedo className="w-3 h-3" />
                    <span>Regenerate Response</span>
                  </button>
                )}

                {message.attachments?.[0]?.file_id ? (
                  <div className="bg-gray-50 border border-blue-300 p-3 mt-3 text-sm text-blue-600 rounded-lg shadow-sm flex items-center space-x-3 hover:bg-blue-100 transition duration-200 ease-in-out">
                    <div className="bg-blue-100 p-2 rounded-full text-blue-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-medium">File ID:</p>
                      <p className="text-sm">
                        {message.attachments[0].file_id}
                      </p>
                    </div>
                  </div>
                ) : (message.filename && message.filename !== "No File") ||
                  message.file ? (
                  <div className="bg-gray-50 border border-green-300 p-3 mt-3 text-sm text-green-600 rounded-lg shadow-sm flex items-center space-x-3 hover:bg-green-100 transition duration-200 ease-in-out">
                    <div className="bg-green-100 p-2 rounded-full text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 7a1 1 0 0 1 1-1h4l2 2h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-medium">File:</p>
                      <p className="text-sm">
                        {message.filename || message.file}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {(message.sender === "user" || message.role === "user") && (
            <FontAwesomeIcon icon={faUser} className="ml-1 text-green-500" />
          )}
        </div>
      ))}
      <div ref={messagesEndRef} />
    </>
  );
};

export default MessageList;
