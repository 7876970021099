import api from "../../utils/api";
import { React, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import { useNavigate } from "react-router-dom";

export default function ProfileTab({
  setActiveSection,
  logout,
  token,
  user,
  userLevel,
}) {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const [isDisabledOne, setIsDisabledOne] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleCancel = async () => {
    setLoading(true);
    setIsDisabled(true);
    try {
      await api.post(
        `/payment/deactivate/subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Subscription canceled successfully");
      setLoading(false);
      setIsDisabled(false);
      setTimeout(() => {
        navigate("/app");
      }, 1500);
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(
          error.response.data.detail || "You are already on the free plan"
        );
      } else {
        toast.error(
          error.response.data?.message || "Error canceling subscription"
        );
      }
      setLoading(false);
      setIsDisabled(false);
    }
  };

  const handleChangePassword = async () => {
    setLoadingOne(true);
    setIsDisabledOne(true);
    try {
      const response = await api.post(
        `/email/forget-password?email=${user.email}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        toast.error("Error sending email to reset password");
      }
      toast.success("We have sent you an email to reset your password");
      setLoadingOne(false);
      setIsDisabledOne(false);
    } catch (error) {
      toast.error("Error sending email to reset password");
      setLoadingOne(false);
      setIsDisabledOne(false);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmCancel = () => {
    handleCancel();
    closeModal();
  };

  return (
    <div className="">
      <section>
        <div className="border border-gray-200 shadow-lg p-6 rounded-lg bg-white">
          <h3 className="text-2xl font-bold mb-4 border-b border-gray-300 pb-2">
            User Information
          </h3>
          <div className="space-y-2">
            <p>
              <span className="font-semibold">Email: </span>
              <span className="break-words">{user.email}</span>
            </p>

            <p>
              <span className="font-semibold">Current Password:</span> ********
            </p>
            <p>
              <span className="font-semibold">Plan Type:</span>{" "}
              {userLevel.charAt(0).toUpperCase() +
                userLevel.slice(1).toLowerCase()}
            </p>
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-4 mt-6">
            <button
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-all shadow-lg sm:mt-0"
              disabled={isDisabledOne}
              onClick={handleChangePassword}
            >
              {loadingOne ? (
                <Loader id="spinner" className="h-6 w-6 mx-auto" />
              ) : (
                "Change Password"
              )}
            </button>
            <button
              onClick={openModal}
              disabled={isDisabled}
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded mt-4 sm:mt-0 transition-all shadow-lg"
            >
              {loading ? (
                <Loader id="spinner" className="h-6 w-6 mx-auto" />
              ) : (
                "Cancel Plan"
              )}
            </button>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-2xl max-w-lg w-full text-sm relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
              aria-label="Close"
            >
              &times;
            </button>

            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              Cancel Subscription
            </h2>

            <p className="mb-4 text-gray-600">
              Your subscription will be cancelled at the end of your billing
              period on <strong>2024</strong>, and you will not be charged
              anymore.
            </p>

            <p className="text-gray-600 mb-1">
              After this date, you will lose access to:
            </p>
            <ul className="text-gray-700 text-xs mb-6 space-y-2 pl-5 list-disc">
              <li>Unlimited Tokens</li>
              <li>File Uploading</li>
              <li>Access to GPT-4</li>
            </ul>

            <div className="flex justify-end space-x-3">
              <button
                onClick={closeModal}
                className="bg-gray-100 text-gray-700 py-2 px-5 rounded-lg hover:bg-gray-200 transition-all"
              >
                Keep Subscription
              </button>

              <button
                onClick={confirmCancel}
                className="bg-red-500 text-white py-2 px-5 rounded-lg hover:bg-red-600 transition-all shadow-md"
              >
                Confirm Cancellation
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
